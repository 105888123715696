import { addTranslations, translate as t } from "@telia/b2b-i18n";
import data from "./data/roles.json";
import en from "./locales/en.json";
import sv from "./locales/sv.json";
import { GetRoleDTO } from "@telia/b2b-rest-client/dist/corp-sra-mybusiness-facade";

export interface Data {
  id: number;
  key: string;
  mandatory?: boolean;
  assignable?: boolean;

  sortOrder: number;
  mutuallyExclusive: boolean; // Check if this is used - else remove
}

export interface Role extends Data {
  name: string;
  description: string;
}

addTranslations({ en, sv });

/**
 * Maps a BE role to Role and adds translations.
 */
export const mapAndAddTranslations = (role: GetRoleDTO): Role => {
  const configRole = data.find((configRole) => configRole.key === role.name);
  if (!configRole) {
    throw new Error("Lib config role missing for BE role: " + role.name);
  }

  return {
    name: t(`roles.${role.name}.name`),
    description: t(`roles.${role.name}.description`),
    id: role.id,
    key: role.name,
    mandatory: role.mandatory,
    assignable: role.assignable,

    sortOrder: configRole.sortOrder,
    mutuallyExclusive: configRole.mutuallyExclusive,
  };
};

export const getRoles = (): Role[] => data.map(libMapTranslations);

const libMapTranslations = (x: Data) => ({
  ...x,
  description: t(`roles.${x.key}.description`),
  name: t(`roles.${x.key}.name`),
});

export const getByKey = (key: string) => getRoles().find((role) => role.key === key);
